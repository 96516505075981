<template>
  <div v-if="hasResource('collection_add')|| hasResource('collection_edit')">
    <div class="add">
      <div class="cursor-pointer width-107 height-46 mg-b-20 text-back" @click="back">
        <i class="iconfont fz-18 black">&#xe647;</i>
        <span class="back-text">返回</span>
      </div>
      <div class="title-content">
        <span>{{!id ? '新增': '编辑'}}</span>推荐系列
      </div>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="60px"
        v-loading="pageInfo.tableLoading"
      >
        <el-form-item label="名称" :required="true" prop="name" class="name-input">
          <el-input v-model="form.name" placeholder="请输入名称" />
        </el-form-item>
        <div class="position-relative">
          <el-form-item label="序号" class="name-input">
            <el-input v-model.number="form.sort" placeholder="请输入序号">
              <i
                slot="suffix"
                class="el-input__icon iconfont"
                @mouseover="showTip"
                @mouseout="hideTip"
              >&#xe60e;</i>
            </el-input>
          </el-form-item>
          <div v-if="pageInfo.isShowTip" class="tooltip">{{pageInfo.tipContent}}</div>
        </div>
        <el-form-item label="状态">
          <el-radio v-model="form.status" label="启用">启用</el-radio>
          <el-radio v-model="form.status" label="停用">停用</el-radio>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="主图"
              :required="true"
              label-width="78px"
              class="inline-form mg-r-120"
            >
              <img v-if="form.mainImage" :src="form.mainImage" alt class="assets-photo" />
              <div v-if="!form.mainImage">
                <el-upload
                  class
                  name="uploadFileSmall"
                  accept=".jpg, .png"
                  action="#"
                  :auto-upload="false"
                  :on-change="handleMain"
                  :show-file-list="false"
                >
                  <el-button class="up-button">上传</el-button>
                  <span class="pd-l-27">(335x610px)</span>
                </el-upload>
              </div>
              <div class="mg-t-20" v-if="form.mainImage">
                <div class="downLoad">
                  <el-button class="up-button">
                    <a :href="form.mainImage" download class="down-button">下载</a>
                  </el-button>
                </div>
                <div style="display: inline-block;">
                  <el-upload
                    name="uploadFileSmall"
                    accept=".jpg, .png"
                    action="#"
                    :auto-upload="false"
                    :on-change="handleMain"
                    :show-file-list="false"
                  >
                    <el-button class="up-button">重新上传</el-button>
                    <span class="pd-l-27">(335x610px)</span>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="详情配图" :required="true" label-width="80px" class="inline-form">
              <img v-if="form.image" :src="form.image" alt class="assets-photo-detail" />
              <!-- <img src="../../../assets/images/skeleton.png" alt> -->
              <div v-if="!form.image">
                <el-upload
                  class
                  name="uploadFile"
                  accept=".jpg, .png"
                  action="#"
                  :auto-upload="false"
                  :on-change="handlePreview"
                  :show-file-list="false"
                >
                  <el-button class="up-button">上传</el-button>
                  <span class="pd-l-27">(1920x440px)</span>
                </el-upload>
              </div>
              <div class="mg-t-20" v-if="form.image">
                <div class="downLoad">
                  <el-button class="up-button">
                    <a :href="form.image" download class="down-button">下载</a>
                  </el-button>
                </div>
                <div style="display: inline-block;">
                  <el-upload
                    name="uploadFile"
                    accept=".jpg, .png"
                    action="#"
                    :auto-upload="false"
                    :on-change="handlePreview"
                    :show-file-list="false"
                  >
                    <el-button class="up-button">重新上传</el-button>
                    <span class="pd-l-27">(1920x440px)</span>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="assets-data" style="display: flex;" v-if="hasResource('collection_asset_list')">
        <div class="pd-r-55 dis-in-b">
          <div class="total-text">筛选资产</div>
          <el-form :inline="true" ref="form" :model="form" :rules="rules">
            <el-form-item label="内部ID" prop="number" class="fw-500 number mg-r-34">
              <el-input v-model="form.number" placeholder="输入编号检索" clearable></el-input>
            </el-form-item>
            <el-form-item label="外部ID/名称" prop="idOrName" class="fw-500 idOrName mg-r-34">
              <el-input v-model="form.idOrName" placeholder="输入ID/名称检索" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <span class="handle-size cursor-pointer fw-bold serach-button" @click="search();">查询</span>
              <span
                class="mg-l-50 handle-size cursor-pointer fw-bold serach-button"
                @click="cancel()"
              >清空</span>
            </el-form-item>
          </el-form>
          <el-table
            header-cell-class-name="table-head"
            cell-class-name="table-cell"
            :data="pageInfo.tableData"
            v-loading="pageInfo.tableLoading"
          >
            <el-table-column
              v-for="col in pageInfo.tableColumns"
              :key="col.prop"
              :prop="col.prop"
              :label="col.label"
              :width="col.width"
              :min-width="col.minWidth"
              :align="col.align"
            >
             <template slot="header">
               <div v-if="col.label === '添加'" class="pd-l-25">添加</div>
              </template>
              <template slot-scope="{row}">
                <div v-if="col.prop =='add'">
                  <i
                    class="iconfont cursor-pointer"
                    v-if="!row.isAdd"
                    @click="handleAdd(row)"
                  >&#xe668;</i>
                  <span class="assets-add" v-if="row.isAdd">已添加</span>
                </div>
                <div v-else-if="col.prop == 'name'" class="assets-name">{{row.name}}</div>
                <div v-else-if="col.prop == 'preview'">
                   <img class="max-height-64 max-width-64" :src="`${row.preview}?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1`"  />
                </div>
                <div v-else>{{row[col.prop]}}</div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="pageInfo.tableData.length != 0"
            class="ta-r mg-t-10"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageNumber"
            :page-sizes="[10,20,50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
        <div class="sortList dis-in-b">
          <div class="total-text">
            添加资产
            <span class="mg-l-12">{{sortTotal}}</span>
          </div>
          <div class="explain">点击右下角确认按钮，将视为成功添加。</div>
          <el-table
            header-cell-class-name="table-head"
            cell-class-name="table-cell"
            :data="pageInfo.assetData"
            row-key="id"
            ref="dragTable"
            v-loading="pageInfo.addLoading"
          >
            <el-table-column
              v-for="col in pageInfo.addColumns"
              :key="col.prop"
              :prop="col.prop"
              :label="col.label"
              :width="col.width"
              :min-width="col.minWidth"
              :align="col.align"
            >
              <template slot="header">
               <div v-if="col.label === '删除'" class="pd-l-20">删除</div>
              </template>
              <template slot-scope="{$index, row}">
                <div v-if="col.prop =='del'" @click="handleDel($index)">
                  <i class="el-icon-delete assets-del-icon cursor-pointer"></i>
                </div>
                <div v-else-if="col.prop =='sort' && hasResource('collection_asset_sort')">
                  <i class="iconfont">&#xe663;</i>
                </div>
                <div v-else-if="col.prop == 'name'" class="assets-name">{{row.name}}</div>
                <div v-else-if="col.prop == 'preview'">
                  <img class="max-height-64 max-width-64" :src="`${row.preview}?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1`" />
              </div>
                <div v-else>{{row[col.prop]}}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        class="add-btn pos-btn"
        :class="hasResource('collection_asset_list')?'':'collection-asset-list'"
      >
        <el-button @click="confirm" class="black-btn btn-s" v-loading="confirmLoading">确 认</el-button>
        <el-button @click="back" class="white-btn btn-s">取 消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../api/asset";
import Sortable from "sortablejs";
export default {
  components: {},
  watch: {
    "form.sort": function (val, oldVal) {},
  },
  data() {
    return {
      id: null,
      form: {
        name: null,
        sort: null,
        status: "启用",
        mainImage: null, // 主图
        image: null, // 详情配图
        number: null,
        idOrName: null,
        addAssetNumber: null,
        addAssetIdOrName: null,
        pageSize: 100,
        pageNumber: 1,
        total: 0,
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      pageInfo: {
        tableColumns: [
          {
            prop:'preview',
            label:"缩略图片",
            width: 180,
            align: "left",
            minWidth: "30%",
          },
          {
            prop: "origin_id",
            label: "内部ID",
            width: 100,
            align: "left",
            minWidth: "20%",
          },
          {
            prop: "asset_uid",
            label: "外部ID",
            align: "left",
            minWidth: "25%",
            width: 120,
          },
          {
            prop: "name",
            label: "资产名称",
            align: "left",
            minWidth: "25%",
            width: 135,
          },
          { prop: "add", label: "添加", align: "left" },
        ],
        addColumns: [
          {
            prop: "sort",
            label: "拖拽排序",
            width: 120,
            align: "left",
            minWidth: "8%",
          },
           {
            prop:'preview',
            label:"缩略图片",
            width: 130,
            align: "left",
            minWidth: "24%",
          },
          {
            prop: "origin_id",
            label: "内部ID",
            align: "left",
            minWidth: "20%",
            width: 150,
          },
          {
            prop: "asset_uid",
            label: "外部ID",
            align: "left",
            minWidth: "20%",
            width: 150,
          },
          {
            prop: "name",
            label: "资产名称",
            align: "left",
            minWidth: "20%",
            width: 150,
          },
          { prop: "del", label: "删除", align: "left" },
        ],
        isShowTip: false,
        tableLoading: false,
        addLoading: false,
        tableData: [],
        assetData: [],
        submitAssetList: [],
        sortArr: [], // 存放拖拽后的数据
        tipContent:
          "根据已有顺序，系统自动生成序列数字，修改数字可调整排列顺序。数字小的优先展示，数字相同的将会插入原本位置。",
        category: null,
        categoryMain: null,
      },
      confirmLoading: false,
      sortTotal: 0,
    };
  },
  methods: {
    async getSortAssetList() {
      let data = {
        limit: this.form.pageSize,
        order_by: null,
        page: this.form.pageNumber,
        filter: {
          category_id: this.id,
          origin_id: null,
          name_id: "",
        },
        search: null,
        market: "US",
      };
      await api.getAssetList(data).then((resp) => {
        if (resp.data.code == 10200) {
          this.pageInfo.assetData = resp.data.data.items;
          this.form.total = parseInt(resp.data.data.total);
          this.sortTotal = this.form.total;
          this.handleEditClass();
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    async getEditInfo() {
      let data = {
        market: "US",
        id: this.$route.query.id,
      };
      await api.recClassInfo(data).then((resp) => {
        if (resp.data.code == 10200) {
          let data = resp.data.data;
          this.form.name = data.name;
          this.form.status = data.status == 1 ? "启用" : "停用";
          this.form.sort = data.sort;
          this.form.mainImage = data.small_image;
          this.form.image = data.image;
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    handleEditClass() {
      // 编辑推荐分类加载筛选的资产数据和已添加的数据
      if (
        this.pageInfo.tableData.length != 0 &&
        this.pageInfo.assetData.length != 0
      ) {
        this.pageInfo.assetData.forEach((ele) => {
          this.pageInfo.tableData.forEach((e) => {
            let isAdd = ele.id == e.id ? true : e.isAdd ? e.isAdd : false;
             this.$set(e, 'isAdd', isAdd);
          });
        });
      }
    },
    back() {
      this.$router.push("/operations/collections");
      this.msgBus.$emit('collectionsList')
    },
    async handleMain(file) {
      let fileMain = file.raw;
      this.categoryMain = fileMain;
      let pathSmall = window.URL.createObjectURL(fileMain);
      let img = new Image();
      img.src = pathSmall;
      await this.asyncImageOnload(img);
      if (
        img.width < 335 ||
        img.height < 610 ||
        img.height / 610 != img.width / 335
      ) {
        this.$$error("请上传尺寸为335px*610px或其倍数的图片");
        return;
      }
      this.form.mainImage = pathSmall;
    },
    async handlePreview(file) {
      let fileRaw = file.raw;
      this.category = fileRaw;
      let path = window.URL.createObjectURL(file.raw);
      let img = new Image();
      img.src = path;
      await this.asyncImageOnload(img);
      if (
        img.width < 1920 ||
        img.height < 440 ||
        img.height / 440 != img.width / 1920
      ) {
        this.$$error("请上传尺寸为1920px*440px或其倍数的图片");
        return;
      }
      this.form.image = path;
    },
    asyncImageOnload(img) {
      return new Promise((resolve) => {
        img.onload = () => {
          resolve();
        };
      });
    },
    search() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.assetList();
        }
      });
    },
    cancel() {
      this.form.number = null;
      this.form.idOrName = null;
      this.assetList();
    },
    async assetList() {
      let data = {
        limit: this.pageSize,
        order_by: "",
        page: this.pageNumber,
        filter: {
          origin_id: this.form.number,
          name_id: this.form.idOrName,
          status: "5",
        },
        search: "",
        market: "US",
      };
      await api.recAssetFilterList(data).then((resp) => {
        if (resp.data.code == 10200) {
          this.pageInfo.tableData = resp.data.data.items;
          this.total = parseInt(resp.data.data.total);
          this.handleEditClass();
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.assetList();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.assetList();
    },
    async motifySort(addId) {
      if (this.pageInfo.assetData.length == 0) return;
      // 先获取所有的已添加的资产
      let arr = this.pageInfo.assetData;
      if (this.pageInfo.sortArr.length != 0) {
        arr = this.pageInfo.sortArr;
      }
      this.confirmLoading = true;
      let sorts = {};
      for (let i = 0; i < arr.length; i++) {
        let item = arr[i];
        sorts[item.id] = i;
      }
      let data = {
        market: "US",
        sort: sorts,
        id: this.$route.query.id || addId,
      };
      await api.addSortAsset(data).then((resp) => {
        if (resp.data.code == 10200) {

        } else {
          this.$$error(resp.data.message);
        }
        this.confirmLoading = false;
      });
    },
    confirm() {
      if (this.checkFun()) {
        return;
      }
      let status = this.form.status == "启用" ? 1 : 0;
      var formData = new FormData();
      formData.append("market", "US");
      formData.append("name", this.form.name);
      formData.append("status", status);
      formData.append("sort", this.form.sort);
      if (this.categoryMain) {
        formData.append("small_image", this.categoryMain);
        if (this.categoryMain.size > 1024 * 1024 * 5) {
          this.$$error("主图过大， 不能超过5M.");
          return;
        }
      }
      if (this.category) {
        formData.append("image", this.category);
        if (this.category.size > 1024 * 1024 * 5) {
          this.$$error("详情图片过大， 不能超过5M.");
          return;
        }
      }
      if (this.$route.query.id) {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            formData.append("id", this.$route.query.id);
            this.confirmLoading = true;
            api.editRecClass(formData).then((res) => {
              if (res.data.code === 10200) {
                this.confirmLoading = false;
                this.$$success("操作成功");
                this.back();
              } else {
                this.$$error(res.data.message);
              }
            });
            if (this.hasResource("collection_asset_sort")) {
              this.motifySort();
            }
          }
        });
      } else {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            let id = "";
            this.confirmLoading = true;
            api.addRecClass(formData).then((res) => {
              if (res.data.code === 10200) {
                this.confirmLoading = false;

                if (this.hasResource("collection_asset_sort")) {
                  id = res.data.data.id;
                  this.motifySort(id);
                }
                this.$refs["form"].resetFields();
                this.$$success("操作成功");
                this.back();
              } else {
                this.$$error(res.data.message);
              }
            });
          }
        });
      }
    },
    checkFun() {
      if (!this.form.name) {
        this.$$error("请填写名称");
        return true;
      } else if (!parseInt(this.form.sort) && parseInt(this.form.sort) != 0) {
        this.$$error("请填写序号");
        return true;
      } else if (!this.form.mainImage) {
        this.$$error("请上传分类主图");
        return true;
      } else if (!this.form.image) {
        this.$$error("请上传详情配图");
        return true;
      } else {
        return false;
      }
    },

    showTip() {
      this.pageInfo.isShowTip = true;
    },
    hideTip() {
      this.pageInfo.isShowTip = false;
    },
    handleAdd(row) {
      if (
        this.pageInfo.assetData.length != 0 &&
        this.pageInfo.assetData.filter((ele) => {
          return ele.id == row.id;
        }).length > 0
      ) {
        this.$$error("该资产已选择啦~");
        return;
      } else {
        this.pageInfo.assetData.push(row);
        this.$set(row, "isAdd", true);
        this.sortTotal++;
      }
    },
    handleDel(index) {
      let data = this.pageInfo.assetData[index];
      this.pageInfo.assetData.splice(index, 1);
      this.sortTotal--;
      if (this.id) {
        this.form.total--;
      }
      this.pageInfo.tableData.forEach((element) => {
        if (element.id == data.id) {
          this.$set(element, 'isAdd', false);
        }
      });
    },
    tableRowDrop() {
      // 表格中需要实现行拖动，选中tr父级元素
      const tbody = document.querySelector(
        ".sortList .el-table__body-wrapper tbody"
      );
      const _this = this;
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.pageInfo.assetData.splice(oldIndex, 1)[0];
          _this.pageInfo.assetData.splice(newIndex, 0, currRow);
          _this.pageInfo.sortArr = _this.pageInfo.sortArr;
        },
      });
    },
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getEditInfo();
      this.assetList();
      this.getSortAssetList();
    } else {
      this.form.sort = this.$route.query.sort;
    }
  },
  mounted() {
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
    if (this.hasResource("collection_asset_sort")) {
      this.tableRowDrop();
    }
  },
};
</script>
<style lang="less">
@import "index.less";
.collection-asset-list {
  margin-top: 280px;
}
</style>
